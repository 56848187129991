<template>
  <div>
        <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a >Dashboard</a></li>
          
        </ol>
        <h2>Dashboard</h2>

      </div>
    </section>
    
    <br />
    <div class="container" v-if="projects">
      <WidgetsDropdown :projectsData="projects" />

      <CRow>
        <CCol md="12">
          <CCard>
            
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CRow>
                    <CCol sm="3" v-for="(project,index) in projects" :key="index">
                      <CCallout color="main">
                        <small class="text-muted">{{ project.header}} Target</small><br />
                        <strong class="h6" v-if="project.target[0].title">
                          <span v-for="(target,index) in project.target" :key="index">
                            {{ Intl.NumberFormat('en-US').format(target.number)+ " " + target.title  }}  <br> 
                          </span>
                          </strong>
                      </CCallout>
                    </CCol>
                    
                  </CRow>
                  <b-row>
                    
                    <b-col md="12" v-for="(project,index) in projects" :key="index">
                      <b-card>
                        <b-card-header class="text-center">
                          {{project.header}}
                        </b-card-header>
                        <b-card-body>
                          <b-row v-if="project.target[0].title">
                            <b-col md="6 card" v-for="(target,index) in project.target" :key="index">
                              <h5 class="text-center">{{target.title}}</h5>
                              <CChartDoughnutExample :jobChart="target"></CChartDoughnutExample>
                            </b-col>
                           
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>

                  </b-row>
                  <CCard md="6">
                    <h3 class="text-center">Overall performance against target</h3>
                    <b-row>
                      <b-col>
                        <CCardBody>
                          <CChartBarExample :jobChart="projects"></CChartBarExample>
                        </CCardBody>
                      </b-col>
                     
                    </b-row>
                  </CCard>
                
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import MainChartExample from "../views/charts/MainChartExample";
import WidgetsDropdown from "../views/widgets/WidgetsDropdown";
import WidgetsBrand from "../views/widgets/WidgetsBrand";
import CChartDoughnutExample from "../views/charts/CChartDoughnutExample";
import CChartBarExample from "../views/charts/CChartBarExample"; 
import { getFields } from '../assets/js/service';
const gaApi = require("ga-api");
var options = {
  clientId:
    "clientidisalongandseeminglyrandomstringofcharacters.apps.googleusercontent.com",
  email:
    "clientidisalongandseeminglyrandomstringofcharacters@developer.gserviceaccount.com",
  key: "google-service-private-key.pem",
  ids: "ga:12345678",
};

export default {

   metaInfo: {
    // Children can override the title.
    title: 'DashBoard',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Dashboard"},
      {property:"og:title" ,content:"First Consult Dashboard"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/dashboard"},


      {name:"twitter:title" ,content:"First Consult Dashboard"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/dashboard"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    CChartDoughnutExample,
    CChartBarExample,
  },
  mounted() {
    let token = localStorage.getItem('token')
    getFields('Projects',token,false,false).then(
      resp=>{
        this.projects = resp.data.filter(pro=>pro.dashboard_status);
      }
    )
  },
  data() {
    return {
      projects : null,

    };
  },
  methods: {
   
  },
};
</script>
<style>
.bg-primary {
  background-color: #3c5887 !important;
}
.primary {
  color: #3c5887 !important;
}
.c-callout-main {
  border-left-color: #3c5887 !important;
}
</style>